<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">
            <template v-for="(item, key) in list_check_box"> 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3> 
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- detail_atu_pgr -->
                    <template  v-if="key == 'detail_atu_pgr' && item.show">
                        <table class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="25%" class="first" >{{$t('Octroi')}}</th>
                                    <th width="25%" >{{$t('Début')}}</th>
                                    <th width="25%" >{{$t('Fin')}}</th>
                                    <th width="25%" class="last" >{{$t('Lien')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{DDMMMYY(data.bp_debut_atu)}}</td>
                                    <td>{{DDMMMYY(data.bp_debut_atu_2)}}</td> 
                                    <td>{{DDMMMYY(data.bp_fin_atu)}}</td> 
                                    <td>
                                        <a :href="data.bp_atu_lien" v-if="data.bp_atu_lien" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            <div class="icon-fiche"></div>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <!-- detail_atu_prix -->
                    <template  v-if="key == 'detail_atu_prix' && item.show">
                        <table class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="50%" class="first" >{{$t('Indemnité maximale (€HT)')}}</th>
                                    <th width="50%" class="last" >{{$t('Date')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_boite_presentation_prix_atu, key_boite_presentation_prix_atu) in data.boite_presentation_prix_atu" :key="'detail_atu_prix_'+key_boite_presentation_prix_atu">
                                    <td>{{format_prix(item_boite_presentation_prix_atu.prix_atu,$i18n.locale)}}</td> 
                                    <td>{{DDMMMYY(item_boite_presentation_prix_atu.atu_date)}}</td> 
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <!-- detail_ss -->
                    <template  v-if="key == 'detail_ss' && item.show">
                        <p  v-if="data.prix_evolution_ville[0]['prix_date_ins_ss'] || data.prix_evolution_ville[0]['prix_date_rad_ss']">{{$t('Sécurité Sociale (SS)')}}</p>
                        <table v-if="data.prix_evolution_ville[0]['prix_date_ins_ss'] || data.prix_evolution_ville[0]['prix_date_rad_ss']" class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="50%" class="first" >{{$t('Inscription')}}</th>
                                    <th width="50%" class="last" >{{$t('Radiation')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td>
                                        <a :href="data.prix_evolution_ville[0]['prix_lien_jo2']" v-if="data.prix_evolution_ville[0]['prix_lien_jo2']" target="_blank" rel="noopener" style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_ville[0]['prix_date_ins_ss'])}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_ville[0]['prix_date_ins_ss'])}}</p>
                                    </td> 

                                    <td>
                                        <a :href="data.prix_evolution_ville[0]['prix_lien_jo3']" v-if="data.prix_evolution_ville[0]['prix_lien_jo3']" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_ville[0]['prix_date_rad_ss'])}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_ville[0]['prix_date_rad_ss'])}}</p>
                                    </td> 

                                </tr>
                            </tbody>
                        </table>

                        <p  v-if="condition_trs(data.prix_evolution_ville, 'prix_ht_ss') || condition_trs(data.prix_evolution_ville, 'prix_ttc_ss') || condition_trs(data.prix_evolution_ville, 'prix_tx_remb_uncam')  || condition_trs(data.prix_evolution_ville, 'prix_date_prix_ss')|| data.prix_evolution_ville[0]['prix_variation_ss']!=0">{{$t('Prix Sécurité Sociale (SS)')}}</p>
                        <table v-if="condition_trs(data.prix_evolution_ville, 'prix_ht_ss') || condition_trs(data.prix_evolution_ville, 'prix_ttc_ss') || condition_trs(data.prix_evolution_ville, 'prix_tx_remb_uncam')  || condition_trs(data.prix_evolution_ville, 'prix_date_prix_ss')|| data.prix_evolution_ville[0]['prix_variation_ss']!=0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="17%" class="first" >{{$t('Prix Fab HT')}}</th>
                                    <th width="17%">{{$t('Prix public TTC')}}</th>
                                    <th width="17%">{{$t('Taux Uncam')}}</th>
                                    <th width="17%">{{$t('Date application')}}</th>
                                    <th width="17%">{{$t('Date JO')}}</th>
                                    <th width="17%" class="last" >{{$t('Variation Prix (%)')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item_detail_ss, key_detail_ss) in data.prix_evolution_ville" >
                                    <tr v-if="item_detail_ss.prix_ht_ss || item_detail_ss.prix_ttc_ss || item_detail_ss.prix_tx_remb_uncam || 
                                            item_detail_ss.prix_date_prix_ss || item_detail_ss.prix_variation_ss != 0" :key="'detail_ss_'+key_detail_ss">
                                        <td>{{format_prix(item_detail_ss.prix_ht_ss,$i18n.locale)}}</td> 
                                        <td>{{format_prix(item_detail_ss.prix_ttc_ss,$i18n.locale)}}</td> 
                                        <td>{{format_prix(item_detail_ss.prix_tx_remb_uncam,$i18n.locale)}}</td> 
                                        <td>{{DDMMMYY(item_detail_ss.prix_date_application_ville)}}</td> 
                                        <td>
                                            <a :href="item_detail_ss.prix_lien_jo" v-if="item_detail_ss.prix_lien_jo" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_detail_ss.prix_date_prix_ss)}}
                                            </a>
                                            <p v-else>{{DDMMMYY(item_detail_ss.prix_date_prix_ss)}}</p>
                                    
                                        </td> 
                                        <td>{{format_prix(item_detail_ss.prix_variation_ss,$i18n.locale)}}</td> 
                                    </tr>
                                </template>
                            </tbody>
                        </table>

                        <p v-if="data.prix_evolution_ville[0]['prix_date_ins_coll'] || data.prix_evolution_ville[0]['prix_date_rad_coll']" >{{$t('Agrément Collectivité (COLL)')}}</p>
                        <table v-if="data.prix_evolution_ville[0]['prix_date_ins_coll'] || data.prix_evolution_ville[0]['prix_date_rad_coll']" class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="50%" class="first" >{{$t('Inscription')}}</th>
                                    <th width="50%" class="last" >{{$t('Radiation')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td>
                                        <a :href="data.prix_evolution_ville[0]['prix_lien_jo4']" v-if="data.prix_evolution_ville[0]['prix_lien_jo4']" target="_blank" rel="noopener" style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_ville[0]['prix_date_ins_coll'])}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_ville[0]['prix_date_ins_coll'])}}</p>
                                    </td> 

                                    <td>
                                        <a :href="data.prix_evolution_ville[0]['prix_lien_jo5']" v-if="data.prix_evolution_ville[0]['prix_lien_jo5']" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_ville[0]['prix_date_rad_coll'])}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_ville[0]['prix_date_rad_coll'])}}</p>
                                    </td> 

                                </tr>
                            </tbody>
                        </table>
                    </template>


                    
                    <!-- detail_group_tfr -->
                    <template  v-if="key == 'detail_group_tfr' && item.show">

                        <p v-if="data.bp_statut_generique">{{$t('Statut')}} : {{data.bp_statut_generique}}</p>
                        <p v-if="data.ansm_date_entree">{{$t('Date d’entrée dans le répertoire')}} : {{DDMMMYY(data.ansm_date_entree)}}</p>
                        <p v-if="data.ansm_date_sortie">{{$t('Date de sortie du groupe')}} : {{DDMMMYY(data.ansm_date_sortie)}}</p>
                        
                        <table v-if="data.ansm.length > 0" class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="25%" class="first" >{{$t('Libellé du groupe')}}</th>
                                    <th width="25%">{{$t('Libellé DCI')}}</th>
                                    <th width="25%">{{$t('Date de création')}}</th>
                                    <th width="25%" class="last" >{{$t('Date de fin')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_ansm, key_ansm) in data.ansm" :key="'ansm_'+key_ansm">
                                    <td>{{item_ansm.libelle}}</td> 
                                    <td>{{item_ansm.libelle_dci}}</td> 
                                    <td>{{DDMMMYY(item_ansm.date_create)}}</td> 
                                    <td>{{DDMMMYY(item_ansm.date_fin)}}</td> 
                                </tr>
                            </tbody>
                        </table>

                        <p v-if="data.tfr.length > 0">TFR</p>
                        <table v-if="data.tfr.length > 0" class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="25%" class="first" >{{$t('Tarif PPTTC')}}</th>
                                    <th width="25%">{{$t('Date JO')}}</th>
                                    <th width="25%">{{$t('Date application')}}</th>
                                    <th width="25%" class="last" >{{$t('Reste à charge')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item_tfr) in data.tfr">
                                    <tr v-for="(item_tfr_detail, key_tfr_detail) in item_tfr.tfr_detail" :key="'ttfr_detail_'+key_tfr_detail">
                                        <td>
                                            {{item_tfr_detail.tarif_ppttc}}</td> 
                                        <td>
                                            <a :href="item_tfr_detail['lien_jo']" v-if="item_tfr_detail['lien_jo']" target="_blank" rel="noopener" style="text-decoration: underline;">
                                                {{DDMMMYY(item_tfr_detail.date_jo)}}
                                            </a>
                                            <p v-else>{{DDMMMYY(item_tfr_detail.date_jo)}}</p>
                                        </td> 
                                        <td>{{DDMMMYY(item_tfr_detail.date_application)}}</td> 
                                        <td>{{item_tfr_detail.rest_charge}}</td> 
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>

                    
                    <!-- detail_group_biosimilaire -->
                    <template  v-if="key == 'detail_group_biosimilaire' && item.show">
                        
                        <p v-if="data.bp_statut_generique">{{$t('Statut')}} : {{data.bp_statut_generique}}</p>
                        <p v-if="data.biosimilaire_date_entree">{{$t('Date d’entrée dans le groupe')}} : {{DDMMMYY(data.biosimilaire_date_entree)}}</p>
                        <p v-if="data.biosimilaire_date_sortie">{{$t('Date de sortie du groupe')}} : {{DDMMMYY(data.biosimilaire_date_sortie)}}</p>
                        

                        <table class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="25%" class="first" >{{$t('Médicament de référence')}}</th>
                                    <th width="25%">{{$t('DCI')}}</th>
                                    <th width="25%">{{$t('Date de création')}}</th>
                                    <th width="25%" class="last" >{{$t('Date de fin')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_biosimilaire, key_biosimilaire) in data.biosimilaire" :key="'detail_biosimilaire_'+key_biosimilaire">
                                    <td>{{item_biosimilaire.medicament_reference}}</td> 
                                    <td>{{item_biosimilaire.substance_active}}</td> 
                                    <td>{{DDMMMYY(item_biosimilaire.date_create_group)}}</td> 
                                    <td>{{DDMMMYY(item_biosimilaire.date_fin_group)}}</td> 
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <!-- detail_t2a -->
                    <template  v-if="key == 'detail_t2a' && item.show">
                        <table class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="15%" class="first" >{{$t('Date info JO')}}</th>
                                    <th width="15%">{{$t("Date d’application")}}</th>
                                    <th width="55%" colspan="3">{{$t('Information JO')}}</th>
                                    <th width="15%" class="last" >{{$t('Délai CT-JO')}}</th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-if="data.prix_evolution_t2a[0].prix_date_ins_t2a">
                                    <td>
                                        <a :href="data.prix_evolution_t2a[0].prix_lien_jo2" v-if="data.prix_evolution_t2a[0].prix_lien_jo2" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_t2a[0].prix_date_ins_t2a)}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_t2a[0].prix_date_ins_t2a)}}</p>
                                    </td> 
                                    <td></td> 
                                    <td colspan="3">{{$t('Inscription')}}</td> 
                                    <td></td> 
                                </tr> 

                                <!-- InfoJo-->
                                <tr v-for="(item_infojo, key_infojo) in data.infojo" :key="'detail_infojo_'+key_infojo">
                                    <td>
                                        <a :href="item_infojo.jo_line_info" v-if="item_infojo.jo_line_info" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(item_infojo.jo_date_info)}}
                                        </a>
                                        <p v-else>{{DDMMMYY(item_infojo.jo_date_info)}}</p>
                                    </td> 
                                    <td><p>{{DDMMMYY(item_infojo.jo_date_application)}}</p></td> 
                                    <td width="10%"><p>{{item_infojo.jo_particularites}}</p></td> 
                                    <td><span v-html="item_infojo.jo_commentaire"></span></td> 
                                    <td width="10%"><span v-html="item_infojo.trs_demand_and_date"></span></td> 
                                    <td><p>{{item_infojo.delay}}</p></td> 
                                </tr> 


                                <tr v-if="data.prix_evolution_t2a[0].prix_date_rad_t2a">
                                    <td>
                                        <a :href="data.prix_evolution_t2a[0].prix_lien_jo3" v-if="data.prix_evolution_t2a[0].prix_lien_jo3" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_t2a[0].prix_date_rad_t2a)}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_t2a[0].prix_date_rad_t2a)}}</p>
                                    </td> 
                                    <td></td> 
                                    <td colspan="3">{{$t('Radiation')}}</td> 
                                    <td></td> 
                                </tr> 

                            </tbody>
                        </table>

                        <p  v-if="data.prix_evolution_t2a.length > 0">{{$t('Prix Evolution T2A')}}</p>
                        <table v-if="data.prix_evolution_t2a.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="25%" class="first" >{{$t('Prix T2A')}}</th>
                                    <th width="25%">{{$t('Date application')}}</th>
                                    <th width="25%">{{$t('Date JO')}}</th>
                                    <th width="25%" class="last" >{{$t('Variation Prix (%)')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_prix_evolution_t2a, key_prix_evolution_t2a) in data.prix_evolution_t2a" :key="'detail_prix_evolution_t2a_'+key_prix_evolution_t2a">
                                    <td>{{format_prix(item_prix_evolution_t2a.prix_t2a,$i18n.locale)}}</td> 
                                    <td>{{DDMMMYY(item_prix_evolution_t2a.prix_date_application_t2a)}}</td> 
                                    <td> 
                                        <a :href="item_prix_evolution_t2a.prix_lien_jo" v-if="item_prix_evolution_t2a.prix_lien_jo" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(item_prix_evolution_t2a.prix_date_prix_t2a)}}
                                        </a>
                                        <p v-else>{{DDMMMYY(item_prix_evolution_t2a.prix_date_prix_t2a)}}</p>
                                    </td> 
                                    <td>{{format_prix(item_prix_evolution_t2a.prix_variation_t2a,$i18n.locale)}}</td> 
                                </tr>
                            </tbody>
                        </table>
                    </template>                    
                    
                    <!-- detail_retrocession -->
                    <template  v-if="key == 'detail_retrocession' && item.show">

                        <table v-if="data.prix_evolution_retro[0]['prix_date_ins_retro'] || data.prix_evolution_retro[0]['prix_date_rad_retro']" class="comparateur2  border-top-gray" >
                            <thead>
                                <tr>
                                    <th width="50%" class="first" >{{$t('Inscription')}}</th>
                                    <th width="50%" class="last" >{{$t('Radiation')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <a :href="data.prix_evolution_retro[0]['prix_lien_jo2']" v-if="data.prix_evolution_retro[0]['prix_lien_jo2']" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_retro[0]['prix_date_ins_retro'])}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_retro[0]['prix_date_ins_retro'])}}</p>
                                    </td> 

                                    <td>
                                        <a :href="data.prix_evolution_retro[0]['prix_lien_jo3']" v-if="data.prix_evolution_retro[0]['prix_lien_jo3']" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(data.prix_evolution_retro[0]['prix_date_rad_retro'])}}
                                        </a>
                                        <p v-else>{{DDMMMYY(data.prix_evolution_retro[0]['prix_date_rad_retro'])}}</p>
                                    </td> 
                                </tr>
                            </tbody>
                        </table>


                        <p  v-if="PrixEvolutionRetro">{{$t('Prix Rétrocession')}}</p>
                        <table v-if="PrixEvolutionRetro" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="20%" class="first" >{{$t('Prix RETRO HT')}}</th>
                                    <th width="20%" v-if="role_admin.includes(user.user_role_id)">{{$t('Taux')}}</th>
                                    <th width="20%">{{$t('Date application')}}</th>
                                    <th width="20%">{{$t('Date JO')}}</th>
                                    <th width="20%" class="last" >{{$t('Variation Prix (%)')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_prix_evolution_retro, key_prix_evolution_retro) in data.prix_evolution_retro" :key="'detail_prix_evolution_retro_'+key_prix_evolution_retro">
                                   <td>{{item_prix_evolution_retro.prix_retro_ht}}</td>
                                   <td  v-if="role_admin.includes(user.user_role_id)">{{item_prix_evolution_retro.prix_tx_remb_uncam}}</td>
                                   <td>{{DDMMMYY(item_prix_evolution_retro.prix_date_application_retro)}}</td>
                                   <td>
                                        <a :href="item_prix_evolution_retro.prix_lien_jo" v-if="item_prix_evolution_retro.prix_lien_jo" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(item_prix_evolution_retro.prix_date_prix_retro)}}
                                        </a>
                                        <p v-else>{{DDMMMYY(item_prix_evolution_retro.prix_date_prix_retro)}}</p>
                                    </td>
                                    <td>{{format_prix(item_prix_evolution_retro.prix_variation_retro,$i18n.locale)}}</td> 
                                </tr>
                            </tbody>
                        </table>
                        <strong v-if="PrixEvolutionRetro_prix_under_atu">{{$t("Retrocession au titre de l’ATU")}}</strong>
                    </template>

                    <!-- detail_rtu -->
                    <template  v-if="key == 'detail_rtu' && item.show">
                        <table v-if="data.boite_presentation_prix_rtu.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="25%" class="first" >{{$t('Prix TTC')}}</th>
                                    <th width="25%">{{$t('Date application')}}</th>
                                    <th width="25%">{{$t('Date JO')}}</th>
                                    <th width="25%" class="last" >{{$t('Variation Prix (%)')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_boite_presentation_prix_rtu, key_boite_presentation_prix_rtu) in data.boite_presentation_prix_rtu" :key="'detail_rtu_'+key_boite_presentation_prix_rtu">
                                   <td>{{format_prix(item_boite_presentation_prix_rtu.prix_rtu,$i18n.locale)}}</td>
                                   <td>{{DDMMMYY(item_boite_presentation_prix_rtu.application_prix_date)}}</td>
                                   <td>
                                       <a :href="item_boite_presentation_prix_rtu.lien_jo" v-if="item_boite_presentation_prix_rtu.lien_jo" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(item_boite_presentation_prix_rtu.prix_date)}}
                                        </a>
                                        <p v-else>{{DDMMMYY(item_boite_presentation_prix_rtu.prix_date)}}</p>
                                   </td>
                                   <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    
                    <!-- detail_date_info_jo -->
                    <template  v-if="key == 'detail_info_jo' && item.show">
                        <table v-if="data.jo_particularites.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="25%" class="first" >{{$t('Date info JO')}}</th>
                                    <th width="25%">{{$t('Avis de Transparence')}}</th>
                                    <th width="25%">{{$t('Particularités JO')}}</th>
                                    <th width="25%" class="last" >{{$t('Commentaire JO')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_jo_particularites, key_jo_particularites) in data.jo_particularites" :key="'detail_info_jo_'+key_jo_particularites">
                                   <td>
                                       <a :href="item_jo_particularites.jo_line_info" v-if="item_jo_particularites.jo_line_info" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{DDMMMYY(item_jo_particularites.jo_date_info)}}
                                        </a>
                                        <p v-else>{{DDMMMYY(item_jo_particularites.jo_date_info)}}</p>
                                   </td>
                                   <td>
                                       <template v-if="item_jo_particularites.infojo_trs_link.length > 0 && item_jo_particularites.infojo_trs_link[0].transparence.length > 0">
                                       {{item_jo_particularites.infojo_trs_link[0].transparence[0].trs_type_demandes}}
                                        <a :href="'/detail/'+item_jo_particularites.infojo_trs_link[0].transparence[0].trs_id+'/trs'" v-if="item_jo_particularites.infojo_trs_link[0].transparence.length > 0" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                            {{item_jo_particularites.infojo_trs_link[0].transparence[0].trs_date}}
                                        </a>
                                       </template>
                                   </td>
                                   <td>{{item_jo_particularites.jo_particularites}}</td>
                                   <td><span v-html="item_jo_particularites.jo_commentaire"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    
                    <!-- detail_indication_jo_info -->
                    <template  v-if="key == 'detail_indication_jo_info' && item.show">
                        <table v-if="indication_jo_info_list.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="35%" class="first" >{{$t('JO texte (indication)')}}</th>
                                    <th width="15%">{{$t('Aire thérapeutique')}}</th>
                                    <th width="10%">{{$t('Avis de Transparence')}}</th>
                                    <th width="10%">{{$t('SS')}}</th>
                                    <th width="10%">{{$t('COLL')}}</th>
                                    <th width="10%">{{$t('T2A')}}</th>
                                    <th width="10%" class="last" >{{$t('RETRO')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item_1, key_1) in indication_jo_info_list" >
                                    <tr v-for="(item_2, key_2) in item_1" :key="'indicaiton_jo_info_'+key_1+key_2">
                                        <td v-if="item_1.length>1 && key_2==0" :rowspan="item_1.length">
                                            <span v-html="item_2.indication_jo.indication"></span>
                                            <template v-for="item_3 in item_1" >
                                                {{item_3.indication_remboursable}}
                                            </template>
                                        </td>
                                        <td v-else-if="key_2==0">
                                            <span v-html="item_2.indication_jo.indication"></span>
                                            <template v-for="item_3 in item_1" > 
                                                {{item_3.indication_remboursable}}
                                            </template>
                                        </td>
                                        <td>
                                            <template v-for="item_indication_jo_info_smr in item_2.indication_jo_info_smr" > 
                                                <template v-if="item_indication_jo_info_smr.transparence_smr && item_indication_jo_info_smr.transparence_smr[0] && item_indication_jo_info_smr.transparence_smr[0].multilanguage_indexations">
                                                    {{item_indication_jo_info_smr.transparence_smr[0].multilanguage_indexations[0]['index_'+$i18n.locale]}}
                                                </template>
                                            </template>
                                        </td>
                                        <td>
                                            <span v-for="(item_indication_jo_info_smr,key_indication_jo_info_smr) in item_2.indication_jo_info_smr" :key="'trs_'+key_indication_jo_info_smr"> 
                                                <template v-if="item_indication_jo_info_smr.transparence_smr && item_indication_jo_info_smr.transparence_smr[0]">
                                                    <template v-if="item_indication_jo_info_smr.transparence_smr[0].transparence">
                                                        {{item_indication_jo_info_smr.transparence_smr[0].transparence.trs_type_demandes}}
                                                        <a :href="'/detail/'+item_indication_jo_info_smr.transparence_smr[0].transparence.trs_id+'/trs'" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                            {{DDMMMYY(item_indication_jo_info_smr.transparence_smr[0].transparence.trs_date)}}
                                                        </a>
                                                    </template>
                                                    <p >{{item_indication_jo_info_smr.transparence_smr[0].smr_type}} <span v-if="item_indication_jo_info_smr.transparence_smr[0].asmr_obtenue">-</span> {{item_indication_jo_info_smr.transparence_smr[0].asmr_obtenue}}</p>  
                                                </template>
                                            </span>
                                        </td>
                                        <td>
                                            <a :href="item_2.ss_link_info" v-if="item_2.particularites_ss=='Radiation'" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.ss_date_application)}}
                                            </a>
                                            <a :href="item_2.ss_link_info" v-else target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.ss_date_info)}}
                                            </a>
                                            <p>{{item_2.particularites_ss}}</p>
                                            <template v-if="item_2.indication_jo_info_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date">
                                                <template v-if="item_2.particularites_ss=='Radiation'">
                                                    <template v-if="item_2.ss_date_application">
                                                        {{datediff_abs(item_2.ss_date_application,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="item_2.ss_date_info">
                                                        {{datediff_abs(item_2.ss_date_info,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                            </template>
                                        </td>
                                        <td>
                                            <a :href="item_2.coll_link_info" v-if="item_2.particularites_coll=='Radiation'" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.coll_date_application)}}
                                            </a>
                                            <a :href="item_2.coll_link_info" v-else target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.coll_date_info)}}
                                            </a>
                                            <p>{{item_2.particularites_coll}}</p>
                                            <template v-if="item_2.indication_jo_info_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date">
                                                <template v-if="item_2.particularites_coll=='Radiation'">
                                                    <template v-if="item_2.coll_date_application">
                                                        {{datediff_abs(item_2.coll_date_application,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="item_2.coll_date_info">
                                                        {{datediff_abs(item_2.coll_date_info,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                            </template>
                                        </td>
                                        <td>
                                            <a :href="item_2.t2a_link_info" v-if="item_2.particularites_t2a=='Radiation'" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.t2a_date_application)}}
                                            </a>
                                            <a :href="item_2.t2a_link_info" v-else target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.t2a_date_info)}}
                                            </a>
                                            <p>{{item_2.particularites_t2a}}</p>
                                            <template v-if="item_2.indication_jo_info_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date">
                                                <template v-if="item_2.particularites_t2a=='Radiation'">
                                                    <template v-if="item_2.t2a_date_application">
                                                        {{datediff_abs(item_2.t2a_date_application,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="item_2.t2a_date_info">
                                                        {{datediff_abs(item_2.t2a_date_info,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                            </template>
                                        </td>
                                        <td>
                                            <a :href="item_2.retro_link_info" v-if="item_2.particularites_retro=='Radiation'" target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.retor_date_application)}}
                                            </a>
                                            <a :href="item_2.retro_link_info" v-else target="_blank" rel="noopener"  style="text-decoration: underline;">
                                                {{DDMMMYY(item_2.retro_date_info)}}
                                            </a>
                                            <p>{{item_2.particularites_retro}}</p>
                                            <template v-if="item_2.indication_jo_info_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date">
                                                <template v-if="item_2.particularites_retro=='Radiation'">
                                                    <template v-if="item_2.retro_date_application && item_2.indication_jo_info_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date">
                                                        {{datediff_abs(item_2.retro_date_application,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="item_2.retro_date_info && item_2.indication_jo_info_smr[0] && item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date">
                                                        {{datediff_abs(item_2.retro_date_info,item_2.indication_jo_info_smr[0].transparence_smr[0].transparence.trs_date)}} {{$t('days')}}
                                                    </template>
                                                </template>
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>

                        


                    <!-- detail_comment -->
                    <template  v-if="key == 'detail_comment' && item.show">
                        <p v-html="data.bp_commentaires"></p>
                    </template>

                    <!-- detail_euro_box -->
                    <template  v-if="key == 'detail_euro_box' && item.show">
                        <table v-if="euro_box_data.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="100%" class="first last" colspan="3" >{{$t('Etat')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_euro, key_euro) in euro_box_data" :key="'detail_euro_'+key_euro">
                                   <td width="20%">
                                       <a :href="'/detail/'+item_euro['trs_id']+'/trs'" target="_blank" rel="noopener">
                                            <em class="icons icon-plus-m"></em>
                                        </a>
                                   </td>
                                   <td width="40%">
                                       <p>{{item_euro.text}}</p>
                                       <p>{{DDMMMYY(item_euro.date)}}</p>
                                   </td>
                                   <td width="40%">
                                       <div :class="'flag ema'" style="width:60px; height:60px; margin:auto;"></div>
                                   </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>


                    <!-- detail_cost_of_treatment -->
                    <template  v-if="key == 'detail_cost_of_treatment' && item.show">
                        <p  v-if="data.cost_treat.length > 0">{{$t('Coût de traitement SS')}}</p>
                        <table v-if="data.cost_treat.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="100%" colspan="2" class="first last" >{{$t('Coût de traitement (€ HT)')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item_cost_treat, key_cost_treat) in data.cost_treat"  :key="'detail_cost_treat_'+key_cost_treat">
                                <tr>
                                   <td colspan="2">
                                       {{item_cost_treat.ct_indication}}
                                   </td>
                                </tr>
                                <tr v-for="(item_cost_treatcost, key_cost_treatcost) in item_cost_treat.cost_treatcost" :key="'detail_cost_treatcost_'+key_cost_treatcost">
                                    <td>{{item_cost_treatcost.ctc_wording}}</td>
                                    <td width="35%">{{format_prix(item_cost_treatcost.ctc_calculation,$i18n.locale)}}</td>
                                </tr>
                                </template>
                            </tbody>
                        </table>


                        <p  v-if="data.cost_treat_t2a.length > 0">{{$t('Coût de traitement T2A')}}</p>
                        <table v-if="data.cost_treat_t2a.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="100%" colspan="2" class="first last" >{{$t('Coût de traitement (€ HT)')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item_cost_treat_t2a, key_cost_treat_t2a) in data.cost_treat_t2a" :key="'detail_cost_treat_t2a_'+key_cost_treat_t2a">
                                <tr>
                                   <td colspan="2">
                                       {{item_cost_treat_t2a.ct_t2a_indication}}
                                   </td>
                                </tr>
                                <tr v-for="(item_cost_treatcost_t2a, key_cost_treatcost_t2a) in item_cost_treat_t2a.cost_treatcost_t2a" :key="'detail_cost_treatcost_t2a_'+key_cost_treatcost_t2a">
                                    <td>{{item_cost_treatcost_t2a.ctc_t2a_wording}}</td>
                                    <td width="35%">{{format_prix(item_cost_treatcost_t2a.ctc_t2a_calculation,$i18n.locale)}}</td>
                                </tr>
                                </template>
                            </tbody>
                        </table>
                    </template>
                    

                    
                    <!-- detail_link_fr -->
                    <template  v-if="key == 'detail_link_fr' && item.show">
                        <table v-if="data.transparence.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="25%" class="first" ></th>
                                    <th width="25%" v-if="condition_trs(data.transparence, 'trs_date')">{{$t('Date Avis')}}</th>
                                    <th width="25%" v-if="condition_trs(data.transparence[0].transparence_smr, 'smr_title') && condition_trs(data.transparence[0].transparence_smr, 'smr_type')">{{$t('SMR')}}</th>
                                    <th width="25%" class="last" v-if="condition_trs(data.transparence[0].transparence_smr, 'asmr_title') && condition_trs(data.transparence[0].transparence_smr, 'asmr_obtenue')">{{$t('ASMR')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_transparence, key_transparence) in data.transparence" :key="'detail_link_fr_'+key_transparence">
                                   <td>
                                        <a :href="'/detail/'+item_transparence['trs_id']+'/trs'" target="_blank" rel="noopener">
                                            <em class="icons icon-plus-m"></em>
                                        </a>
                                   </td>
                                   <td v-if="condition_trs(data.transparence, 'trs_date')">
                                        <p>{{DDMMMYY(item_transparence.trs_date)}}</p>
                                        <!-- <a v-if="$i18n.locale=='fr'&&item_transparence.trs_lien_pdf" :href="item_transparence.trs_lien_pdf" target="_blank" rel="noopener">PDF</a>
                                        <a v-else-if="item_transparence.trs_lien_pdf_en" :href="item_transparence.trs_lien_pdf_en" target="_blank" rel="noopener">PDF</a>
                                        <p>{{item_transparence.trs_type_demandes}}</p>
                                        <p>{{item_transparence.trs_motif}}</p> -->
                                   </td>
                                   <td v-if="condition_trs(data.transparence[0].transparence_smr, 'smr_title') && condition_trs(data.transparence[0].transparence_smr, 'smr_type')">
                                        <p v-if="item_transparence.transparence_smr[0] && item_transparence.transparence_smr[0].smr_title">{{item_transparence.transparence_smr[0].smr_title}}</p>
                                        <p v-if="item_transparence.transparence_smr[0] && item_transparence.transparence_smr[0].smr_type">{{item_transparence.transparence_smr[0].smr_type}}</p>
                                        <span v-if="!item_transparence.transparence_smr[0] || (item_transparence.transparence_smr[0] && !item_transparence.transparence_smr[0].smr_title && !item_transparence.transparence_smr[0].smr_type)"> - </span>
                                    </td>
                                   <td v-if="condition_trs(data.transparence[0].transparence_smr, 'asmr_title') && condition_trs(data.transparence[0].transparence_smr, 'asmr_obtenue')">
                                        <p v-if="item_transparence.transparence_smr[0] && item_transparence.transparence_smr[0].asmr_title">{{item_transparence.transparence_smr[0].asmr_title}}</p>
                                        <p v-if="item_transparence.transparence_smr[0] && item_transparence.transparence_smr[0].asmr_obtenue">{{item_transparence.transparence_smr[0].asmr_obtenue}}</p>
                                        <span v-if="!item_transparence.transparence_smr[0] || (item_transparence.transparence_smr[0] && !item_transparence.transparence_smr[0].asmr_title && !item_transparence.transparence_smr[0].asmr_obtenue)"> - </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <!-- detail_link_rtu -->
                    <template  v-if="key == 'detail_link_rtu' && item.show">

                        <table v-if="data.rtu.length > 0" class="comparateur2  border-top-gray">
                            <thead>
                                <tr>
                                    <th width="25%" class="first" ></th>
                                    <th width="25%">{{$t('Recommandation')}}</th>
                                    <th width="25%">{{$t('Date')}}</th>
                                    <th width="25%" class="last" >{{$t('Avis rendu')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item_rtu, key_rtu) in data.rtu" :key="'detail_link_fr_'+key_rtu">
                                   <td>
                                        <a :href="'/detail/'+item_rtu['id']+'/rtu'" target="_blank" rel="noopener">
                                            <em class="icons icon-plus-m"></em>
                                        </a>
                                   </td>
                                   <td>
                                        <a v-if="item_rtu.recommandation" :href="item_rtu.recommandation" target="_blank" rel="noopener">PDF</a>
                                   </td>
                                   <td><p v-if="item_rtu.date">{{DDMMMYY(item_rtu.date)}}</p></td>
                                   <td><p v-if="item_rtu.avis_pour_prise">{{item_rtu.avis_pour_prise}}</p></td>
                                   
                                </tr>
                            </tbody>
                        </table>
                    </template>


                    <!-- Section Detail End -->

                </div>
            </template>


        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import { format_prix, DDMMMYY, datediff_abs } from '../../../utils'
   
export default {
    name: 'boite_presentation',
    components : {
        LeftSection,
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_atu_pgr : {'title' : 'ATU', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_atu_prix : {'title' : 'ATU Prix', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_ss : {'title' : 'SS / COLL', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_group_tfr : {'title' : 'Groupe générique / TFR', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_group_biosimilaire : {'title' : 'Groupe biosimilaire', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_t2a : {'title' : 'Liste en sus (T2A)', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_retrocession : {'title' : 'Rétrocession', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_rtu : {'title' : 'RTU', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_info_jo : {'title' : 'Particularites JO', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_indication_jo_info : {'title' : 'Prise en charge', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_comment : {'title' : 'Commentaires', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_euro_box : {'title' : 'Etat', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_cost_of_treatment : {'title' : 'Coût de traitement', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_link_fr : {'title' : 'CT : Commission de la Transparence', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_link_rtu : {'title' : 'RTU : RTU', 'show' : true, 'enable' : false, 'orange_text':false },
            },
            PrixEvolutionRetro : false,
            PrixEvolutionRetro_prix_under_atu : false,
            indication_jo_info_list : [],
            euro_box_data : [],
        }
    },
    created() {
        // condition to show check box in left section [enable]
        if(this.data){
            
            if(this.data.bp_debut_atu || this.data.bp_debut_atu_2 ||this.data.bp_fin_atu ||this.data.bp_atu_lien ){ 
                this.list_check_box['detail_atu_pgr'].enable = true
            }     

            if(this.data.boite_presentation_prix_atu && this.data.boite_presentation_prix_atu.length > 0){
                this.list_check_box['detail_atu_prix'].enable = true
            }

            if(this.data.prix_evolution_ville && this.data.prix_evolution_ville.length > 0 &&
                ((this.data.prix_evolution_ville[0]['prix_date_ins_ss'] || this.data.prix_evolution_ville[0]['prix_date_rad_ss']) &&
                (this.condition_trs(this.data.prix_evolution_ville, 'prix_ht_ss') || this.condition_trs(this.data.prix_evolution_ville, 'prix_ttc_ss') || 
                this.condition_trs(this.data.prix_evolution_ville, 'prix_tx_remb_uncam')  || this.condition_trs(this.data.prix_evolution_ville, 'prix_date_prix_ss')) && 
                (this.data.prix_evolution_ville[0]['prix_variation_ss'] || this.data.prix_evolution_ville[0]['prix_date_ins_coll'] || this.data.prix_evolution_ville[0]['prix_date_rad_coll']))
            ){
                this.list_check_box['detail_ss'].enable = true
            }

            if((this.data.ansm && this.data.ansm.length > 0) || (this.data.tfr && this.data.tfr.length > 0)){
                for(let key in this.data.tfr){
                    for(let key2 in this.data.tfr[key].tfr_detail){
                        for(let key3 in this.data.tfr_rest_charge){
                            if(this.data.tfr[key].tfr_detail[key2].id == this.data.tfr_rest_charge[key3].tfr_detail_id){
                                this.data.tfr[key].tfr_detail[key2]['rest_charge'] = this.data.tfr_rest_charge[key3].rest_charge
                            }
                        }
                    }
                }
                this.list_check_box['detail_group_tfr'].enable = true
            }

            if(this.data.biosimilaire && this.data.biosimilaire.length > 0){
                this.list_check_box['detail_group_biosimilaire'].enable = true 
            }

            if((this.data.prix_evolution_t2a && this.data.prix_evolution_t2a.length > 0) || (this.data.infojo && this.data.infojo.length > 0)){
                    for(let key in this.data.infojo){
                    this.data.infojo[key]['delay'] = null;
                    this.data.infojo[key]['trs_demand_and_date'] = '';
                    for(let key2 in this.data.infojo[key].infojo_trs_link){
                        let smr_type = ''
                        let asmr_obtenue = ''
                        if(
                            this.data.infojo[key].infojo_trs_link[key2] && 
                            this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0] &&
                            this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0].smr[0] &&
                            this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0].smr[0].smr_type){
                            smr_type += '<p>'+this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0].smr[0].smr_type+'</p>'
                        }
                        if(
                            this.data.infojo[key].infojo_trs_link[key2] && 
                            this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0] && 
                            this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0].asmr[0] &&
                            this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0].asmr[0].asmr_obtenue){
                            asmr_obtenue += '<p>'+this.data.infojo[key].infojo_trs_link[key2].infojo_trs_link_smr[0].asmr[0].asmr_obtenue+'</p>'
                        }
                        if(
                            this.data.infojo[key].infojo_trs_link[key2] &&
                            this.data.infojo[key].infojo_trs_link[key2].transparence[0] &&
                            this.data.infojo[key].infojo_trs_link[key2].transparence[0].trs_date &&
                            this.data.infojo[key].jo_date_info){
                            this.data.infojo[key]['delay'] = this.datediff_abs(this.data.infojo[key].jo_date_info,this.data.infojo[key].infojo_trs_link[key2].transparence[0].trs_date)
                        }
                        this.data.infojo[key]['trs_demand_and_date'] += '<p>'+this.data.infojo[key].infojo_trs_link[key2].transparence[0].trs_type_demandes+' <a href="/detail/'+this.data.infojo[key].infojo_trs_link[key2].transparence[0].trs_id+'/trs" target="_blank" rel="noopener">'+this.DDMMMYY(this.data.infojo[key].infojo_trs_link[key2].transparence[0].trs_date)+'</a></p>'+smr_type+asmr_obtenue;
                    }
                }
                this.list_check_box['detail_t2a'].enable = true 
            }


            if(this.data.prix_evolution_retro && this.data.prix_evolution_retro.length > 0){
                for(let key in this.data.prix_evolution_retro){
                    if(this.data.prix_evolution_retro[key].prix_retro_ht * 1 != 0){
                        this.PrixEvolutionRetro = true
                    }
                    if(this.data.prix_evolution_retro[key].prix_under_atu == 1){
                        this.PrixEvolutionRetro_prix_under_atu = true
                    }
                }
                this.list_check_box['detail_retrocession'].enable = true
            }

            if(this.data.boite_presentation_prix_rtu && this.data.boite_presentation_prix_rtu.length > 0){
                this.list_check_box['detail_rtu'].enable = true 
            }

            if(this.data.jo_particularites && this.data.jo_particularites.length > 0){
                this.list_check_box['detail_info_jo'].enable = true 
            }

            if(this.data.indication_jo_info && this.data.indication_jo_info.length > 0){
                let arr_indication_jo_info = []
                for(let key in this.data.indication_jo_info){
                    if(!arr_indication_jo_info[this.data.indication_jo_info[key].indication_jo_id]){
                        arr_indication_jo_info[this.data.indication_jo_info[key].indication_jo_id] = []
                    }
                    arr_indication_jo_info[this.data.indication_jo_info[key].indication_jo_id].push(this.data.indication_jo_info[key])
                }

                for(let key in arr_indication_jo_info){
                    this.indication_jo_info_list.push(arr_indication_jo_info[key])
                }
                this.list_check_box['detail_indication_jo_info'].enable = true
            }



            if(this.data.bp_commentaires ){
                this.list_check_box['detail_comment'].enable = true
            }


            if(this.data.transparence && this.data.transparence.length > 0){

                for(let key in this.data.transparence){

                    let cond_CHMP_EPAR_newest = false;
                    let cond_no_CT = false;
                    let cond_nothing = false;

                    let post_fix = ''
                    if(this.$i18n.locale != 'fr'){
                        post_fix = '_en'
                    }

                    let date_CHMP = null;
                    let date_EPAR = null;
                    if(this.data.transparence[key]['trs_date_amm'+post_fix]){
                        date_CHMP = new Date(this.data.transparence[key]['trs_date_amm'+post_fix])
                    }
                    if(this.data.transparence[key]['trs_date_epar'+post_fix]){
                        date_EPAR = new Date(this.data.transparence[key]['trs_date_epar'+post_fix])
                    }

                    let max_dates=[];
                    if(this.data.transparence[key].trs_ordre_du_jour_date){
                        max_dates.push(new Date(this.data.transparence[key].trs_ordre_du_jour_date))
                    }
                    if(this.data.transparence[key].trs_compte_rendu_date){
                        max_dates.push(new Date(this.data.transparence[key].trs_compte_rendu_date) )
                    }
                    if(this.data.transparence[key].trs_audition_date){
                        max_dates.push(new Date(this.data.transparence[key].trs_audition_date) )
                    }
                    if(this.data.transparence[key].trs_date){
                        max_dates.push(new Date(this.data.transparence[key].trs_date))
                    }

                    //conditions for checking
                    if(!this.data.transparence[key]['trs_date_amm'+post_fix] && this.data.transparence[key]['trs_date_epar'+post_fix] && !this.data.transparence[key].trs_ordre_du_jour_ct && !this.data.transparence[key].trs_compte_rendu_ct && !this.data.transparence[key].trs_audition_ct && !this.data.transparence[key].trs_lien_pdf){
                        cond_nothing = true;
                    }
                    if(!this.data.transparence[key].trs_ordre_du_jour_ct && !this.data.transparence[key].trs_compte_rendu_ct && !this.data.transparence[key].trs_audition_ct && !this.data.transparence[key].trs_lien_pdf){
                        cond_no_CT = true;
                    }
                    cond_CHMP_EPAR_newest = (date_CHMP || date_EPAR) > new Date(Math.max.apply(null, max_dates))


                    if(!cond_nothing){
                        if(cond_no_CT||cond_CHMP_EPAR_newest){
                            let euro_tmp_data = []
                            if(!euro_tmp_data['trs_id']){
                                euro_tmp_data['trs_id'] = null;
                            }
                            if(!euro_tmp_data['text']){
                                euro_tmp_data['text'] = null;
                            }
                            if(!euro_tmp_data['date']){
                                euro_tmp_data['date'] = null;
                            }
                            
                            euro_tmp_data['trs_id'] = this.data.transparence[key].trs_id;
                            if(date_EPAR > date_CHMP){
                                euro_tmp_data['text'] = 'EPAR'
                                euro_tmp_data['date'] = this.data.transparence[key]['trs_date_epar'+post_fix]
                            }else{
                                euro_tmp_data['text'] = 'CHMP'
                                euro_tmp_data['date'] = this.data.transparence[key]['trs_date_amm'+post_fix]
                            }
                            if(euro_tmp_data['date']){
                                this.euro_box_data.push(euro_tmp_data)
                            }
                        }
                    }
                }
                
                if(this.euro_box_data.length > 0){
                    this.list_check_box['detail_euro_box'].enable = true
                }
            }

            if(this.role_admin.includes(this.user.user_role_id) && ((this.data.cost_treat && this.data.cost_treat.length > 0) || (this.data.cost_treat_t2a && this.data.cost_treat_t2a.length > 0 ))){
                this.list_check_box['detail_cost_of_treatment'].enable = true
            }

            if(this.condition_trs(this.data.transparence, 'trs_date') || 
                this.condition_trs(this.data.transparence[0].transparence_smr, 'smr_title') || 
                this.condition_trs(this.data.transparence[0].transparence_smr, 'smr_type') ||
                this.condition_trs(this.data.transparence[0].transparence_smr, 'asmr_title') || 
                this.condition_trs(this.data.transparence[0].transparence_smr, 'asmr_obtenue')
            ){
                this.list_check_box['detail_link_fr'].enable = true
            }
            
            if(this.data.rtu && this.data.rtu.length > 0){
                this.list_check_box['detail_link_rtu'].enable = true
            }
        }
            
    },
    methods : {
        format_prix,
        DDMMMYY,
        datediff_abs,
        condition_trs(datas, column){
            let result = false;
            datas.forEach(function(value){
                if(value[column]){
                    result = true
                }
            })
            return result
        }
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        role_admin(){
            return [1,3,10]
        },
        user(){
            return this.$store.getters['auth/user']
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }       
    },
}
</script>

<style scoped>
.table th, .table td {
    padding: 15px;
}
a{
    color:#000;
}
</style>
